@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.project_impact {
	display: flex;

	.info_text {
		font-weight: 400;
		font-size: 12px;
		line-height: 17px;
		color: #8c8c8c;
		flex: 1;
		padding: 6px;
		margin-left: 15px;
		display: flex;
		align-items: flex-start;

		svg {
			color: #8c8c8c;
			width: 12px;
			height: 12px;
			margin-right: 4px;
			margin-top: 2px;
		}
	}

	.impact_text {
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 14px;
		color: #ffffff;
		text-align: left;
	}

	&>div {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		background: #191a1b;

		@include media($phone_all...) {
			width: 100%;
		}

		&>img {
			width: 12px;
			position: absolute;
			top: 10px;
			right: 10px;
		}

		&:last-child {
			background-color: transparent;
			padding-left: 0.75rem;
			border-left: 1px solid #191a1b;
			border-radius: 0 14px 14px 0;

			@include media($phone_all...) {
				border-radius: 0 0 14px 14px;
				padding: 0.75rem;
			}

			h2 {
				margin-bottom: 10px !important;

				@include media($phone_all...) {
					margin-top: 0.25rem;
					margin-bottom: 17px !important;
				}
			}
		}

		.impact_info {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			justify-content: center;
			padding: 15px;

			@include media($phone_all...) {
				width: 95%;
			}

			.impact_header {
				display: none;

				@include media($phone_all...) {
					font-size: 14px;
					font-weight: 400;
					line-height: 24px;
					margin-bottom: 20px;
					display: block !important;
				}
			}

			.ranks {
				position: relative;
				border-radius: 4px;
				border: 1px solid rgba(1, 118, 255, 0.30) !important;
				background: radial-gradient(233.44% 138.3% at 1.32% 3.1%, rgba(1, 118, 255, 0.32) 0%, rgba(1, 118, 255, 0.08) 100%);
				backdrop-filter: blur(10px);
				margin-bottom: 6px;

				.next_level {
					&::before {
						content: '';
						height: 25px;
						width: 1px !important;
						margin-right: 8px;
						background: #0176ff4d;
					}
				}
			}

			.locked {
				position: relative;
				background: radial-gradient(98.68% 212.38% at 1.32% 3.1%,
						rgba(255, 255, 255, 0.32) 0%,
						rgba(255, 255, 255, 0.08) 100%);
				border: 1px solid #ffffff4d !important;
				margin-bottom: 6px;

				.next_level {
					&::before {
						content: '';
						height: 25px;
						width: 1px !important;
						margin-right: 8px;
						background: #ffffff4d;
					}

					&.graytext {
						opacity: 25%;
					}
				}
			}

			.ranks,
			.locked {
				height: 51px;
				padding: 12px 8px;
				border-radius: 4px;
				border: 1px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;

				img.rank_img {
					width: 50px;
				}

				.perk_program_icon {
					height: 35px;

					&.is_on_perks_program {
						opacity: 25%;
					}
				}

				.next_level,
				.levels_section {
					color: #FFF;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 14px;

					&:before {
						content: '';
						width: 55px;
					}

					span {
						color: #e961ff;
					}
				}

				.separator {
					width: 1px;
					height: 27px;
					background: rgba(1, 118, 255, 0.30);
				}
			}

			.progressBox {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 12px;

				.levelText {
					font-size: 12px;
					font-weight: 600;
					line-height: 14px;
					text-wrap: nowrap;
					text-transform: capitalize !important;
				}
			}

			.superfan {
				display: flex;
				justify-content: center;

				.next_level_info {
					color: #aaa;
				}

				.level_metrics {
					margin-top: 10px;
					margin-right: 0;
					display: flex;
					justify-content: flex-start;
					width: 100%;

					.level_metric {
						display: flex;
						padding-top: 0;
						margin-right: 0.75rem;

						p {
							font-size: 12px;
							font-weight: 400;
							line-height: 10px;
							margin: 0;
						}

						svg {
							float: left;
							color: #08f996;
							width: 14px;
							height: 12px;
							margin-right: 4px;
							margin-top: 0px;
						}
					}
				}

				.almost {
					font-weight: 600;
				}
			}

			button {
				background: #01bf52;
				border-radius: 25px;
				color: #fff;
				text-transform: uppercase;
				height: 30px;
				width: 90px;
				font-size: 12px;
				font-weight: 600;
				margin-top: 12px;

				animation-name: pulse_animation;
				animation-duration: 2s;
				transform-origin: 70% 70%;
				animation-iteration-count: 3;
				animation-timing-function: linear;

				&:disabled {
					background: #134329;
					color: #535354;
					animation-name: none;
				}
			}

			h2 {
				@extend .impact_text;
				margin-bottom: 5px;

				img {
					position: relative;
					top: 0.3rem;
					margin: 0;
					margin-right: 0.25rem;
				}

				span {
					color: #e961ff;
				}

				span.next_level {
					color: #fff;
					font-size: 0.9375rem;
					position: relative;
					float: right;
					top: 0.6rem;
				}
			}

			h3 {
				@extend .impact_text;
				font-size: 14px;
				margin-bottom: 8px;

				&.earned_fan {
					display: flex;
					align-items: center;
					color: #59b7d8;

					img {
						margin-right: 4px;
					}
				}
			}

			p {
				font-size: 11px;
				font-weight: 300;
				line-height: 24px;
				letter-spacing: normal;
				height: 18px;

				span {
					color: #3cbb5b;
				}
			}
		}

		.benefits {
			display: flex;
			width: 100%;
			color: #8c8c8c;

			p {
				span {
					color: #8c8c8c;
				}

				em {
					color: #fff;
					font-style: normal;
				}

				strong {
					color: #fff;

					span {
						color: #3cbb5b;
					}
				}
			}
		}

		.fgr_info {
			@extend .impact_info;

			@include media($phone_all...) {
				flex-direction: row;
				width: 100%;
				justify-content: space-around;
			}
		}

		.pie {
			margin-right: 14px;

			img {
				width: 25px;
				height: 20px;
			}

			.lock_progress {
				width: 100%;
				height: 100%;
				display: flex;
				position: absolute;
				background-color: #00000069;
				justify-content: center;
				align-items: center;
				border-radius: 50%;

				img {
					width: 25px;
					height: 25px;
				}
			}

			.lock_progress {
				width: 100%;
				height: 100%;
				display: flex;
				position: absolute;
				background-color: #00000069;
				justify-content: center;
				align-items: center;
				border-radius: 50%;

				img {
					width: 25px;
					height: 25px;
				}
			}
		}

		.info {
			flex-basis: 25%;

			p {
				@extend .info_text;
				font-size: 10px;
				text-align: center;
				display: block;
			}
		}
	}

	.complete {}

	.incomplete {
		opacity: 0.5;
	}
}

@keyframes pulse_animation {
	0% {
		transform: scale(1);
		background: #01bf52;
	}

	8% {
		transform: scale(1.01);
	}

	16% {
		transform: scale(1.02);
	}

	24% {
		transform: scale(1.03);
	}

	32% {
		transform: scale(1.04);
	}

	40% {
		transform: scale(1.05);
	}

	50% {
		transform: scale(1.06);
		background: #257839;
	}

	58% {
		transform: scale(1.05);
	}

	66% {
		transform: scale(1.04);
	}

	74% {
		transform: scale(1.03);
	}

	82% {
		transform: scale(1.02);
	}

	90% {
		transform: scale(1.01);
	}

	100% {
		transform: scale(1);
		background: #01bf52;
	}
}