#share_menu {
	.menu_paper {
		border-radius: 11px;
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		color: #fff;
		margin-top: 4px;

		ul {
			padding: 5px 20px 5px 5px;

			li {
				margin: 5px 0;
			}
		}
	}

	span {
		font-size: 14px;
	}

	svg {
		color: #fff;
		width: 20px;
		height: 20px;
	}
}
