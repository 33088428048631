.basic_profile_link {
	display: inline-flex;
	align-items: center;

	.name {
		font-size: 0.9rem;
		font-weight: normal;
		line-height: 2rem;
		color: #fff;
	}
}

.team_member_profile_link {
	color: #fff;
	display: flex;

	.info_container {
		display: flex;
		flex-direction: column;

		.info {
			.name {
				font-weight: 600;
				font-size: 1.1rem;
			}
		}

		.role {
			font-size: 0.8rem;
			font-weight: 600;
		}

		.username {
			font-size: 0.8rem;
			margin-top: -0.5rem;
		}
	}
}
