.badge_preview {
	border-radius: 0.5rem;
	background: #002259 !important;
	height: 380px;
	width: 240px;
	position: relative;
	display: table;
	box-shadow: 0rem 0rem 0.75rem #000;

	.badge_status {
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: center;
	}

	.reward {
		background: #f4a01d;
		position: absolute;
		top: 1rem;
		left: -0.75rem;
		padding: 0.25rem 0.75rem;
		border-radius: 6px;
		color: #fff;
		text-align: center;
		font-size: 0.9rem;
		box-shadow: 0rem 0rem 0.75rem rgb(0 0 0 / 25%);
	}

	.badge_image {
		width: 100%;
		text-align: center;
		margin-bottom: 0.5rem;
		margin-top: 30px;

		img {
			width: 35%;
		}
	}

	h2 {
		color: #fff;
		font-size: 1.5rem;
		max-width: 80%;
		margin: 0 auto;
		text-align: center;
	}

	p {
		color: #fff;
		font-size: 0.9rem;
		line-height: 1.1rem;
		padding: 0 1rem;
		text-align: left;
	}

	.creator_reward {
		font-size: 0.9rem;
		line-height: 1.2rem;
		font-weight: 600;
		text-align: center;

		span {
			color: #f4a01d;
		}
	}
}
