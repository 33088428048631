@import 'Variable.scss';

.form_control {
	width: 100%;

	h3 {
		color: #fff;
		margin-bottom: 10px;
	}

	input {
		border-radius: 8px;
		height: 45px;
		padding: 0.11rem 1rem !important;
		color: #fff;

		&:disabled {
			color: #ffffff96;
			-webkit-text-fill-color: #ffffff96;
		}
	}

	.helper_text_right {
		position: absolute;
		right: 0;
		margin-right: 0;
		bottom: 0;
	}
}

.black {
	input {
		color: $whiteColor;
		background-color: $blackColor;
		border: 1px solid #746064;

		&:hover,
		&:focus {
			background-color: $blackColor;
		}
	}
}

.grey {
	input {
		background-color: #262626;
		border: none;

		&::selection {
			background-color: #53abc9;
			color: #fff;
		}
	}
}

.blue {
	input {
		padding: 8px 16px;
		background-color: #2e2c6a;
		border: 1px solid #5553da;
		border-radius: 0.5rem;
	}
}

.newsletter {
	input {
		margin-top: 0.5rem;
		width: 100%;
		border: none;
		border-radius: 1.5rem !important;
		padding: 0.5rem 0.75rem !important;
		font-size: 1rem;
		color: #fff;
		height: 20px;
		background: #000;
		border: 1px solid #737373;

		&::placeholder {
			color: #8e8e8e;
			font-weight: 600;
		}
	}
}

.error {
	h3 {
		color: #d32f2f;
		font-weight: bold;
	}

	input {
		border: 1px solid #d32f2f;
	}
}

/* Redesign 08-2024 */
.blue_search {
	input {
		display: flex;
		width: 100% !important;
		height: 35px;
		padding: var(--none, 0px) 12px;
		padding-left: 40px !important;
		align-items: center;
		gap: 10px;
		flex-shrink: 1;
		border-radius: var(--radius-rounded-lg, 8px);
		border: 1px solid #4a4a4a;
	}
}
