@import 'Variable';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stake_table {
	margin-top: 1.75rem;
	position: relative;

	@include media($phone_all...) {
		width: 100% !important;
		height: auto !important;
	}

	table {
		width: 100%;
		font-size: 1.1rem;
		text-align: left;
		background: none;

		.center {
			text-align: center;
		}

		th {
			font-weight: normal;
			font-size: 1rem;
			padding: 0.5rem;
			border-bottom: 0.05rem solid #373833;
			background-color: transparent;

			span,
			svg {
				color: #fff !important;
				line-height: 1.6rem;
			}

			@include media($phone_all...) {
				font-size: 14px;
				letter-spacing: normal;
				line-height: 18px;
			}
		}

		tr {
			&:hover {
				background-color: $bodyBg;
			}
		}

		td {
			color: #fff;
			padding: 0.5rem;
			border: none;
			font-size: 16px;

			@include media($phone_all...) {
				font-size: 12px;
				letter-spacing: normal;
				line-height: 18px;
			}

			a {
				color: #2887BD !important;
				display: block;
				font-weight: 600;
				padding: 0.5rem 0 !important;
				text-decoration: underline;
			}

			button {
				&:disabled {
					opacity: 0.5
				}
				
				font-size: 13px;
				padding: 0.25rem 0.25rem;
				border: 1px solid #fff;
				font-weight: 600;
			}

			&.status {
				
				span {
					color: #fff;
					text-transform: uppercase;
					padding: 0.25rem 0.25rem;
					font-size: 13px;
					border-radius: 0.25rem;
					align-items: center;
					text-align: center;
					display: inline-block;
					width: 100%;
					max-width: 100px;

					@include media($phone_all...) {
						font-size: 13px;
						letter-spacing: normal;
						padding: 2px 6px;
					}
				}

				.completed {
					font-weight: 600;
					border: 1px solid #2dce89;
					color: #2dce89;
				}

				.processing {
					border: 1px solid #A3ABFA;
					color: #A3ABFA;
				}

				.reclaimed {
					border: 1px solid #fff;
					color: #fff;
				}
				
				.failed {
					border: 1px solid #FC9CAA;
					color: #FC9CAA;
				}
			}
		}
	}

	.pagination {
		* {
			color: #ffffff;
		}

		button {
			&:disabled {
				* {
					color: #ffffff52;
				}
			}
		}
	}
}
