@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.subtitle {
	font-weight: 700;
	font-size: 16px;
	margin-top: 32px;
	margin-bottom: 12px;
	color: #ffffff;
}

@mixin flexbox($direction: row, $justify: flex-start, $align: flex-start) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
}

.header_title {
	margin-top: 25px !important;
	position: relative;

	@include media($phone_all...) {
		margin-top: 10px !important;
	}

	p {
		font-weight: 400;
		font-size: 17px;
		line-height: 24px;
		color: #ffffff;

		@include media($phone_all...) {
			font-size: 14px;
		}
	}

	.greenlightHeader {
		span {
			svg {
				cursor: pointer;
				font-size: 0.9rem;
				color: #01b6df;
				opacity: 0.5;
				transition: all 200ms ease-in;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.impactHeader {
		span {
			svg {
				cursor: pointer;
				font-size: 0.9rem;
				color: #01b6df;
				opacity: 0.5;
				transition: all 200ms ease-in;

				&:hover {
					opacity: 1;
				}
			}
		}

		@include media($phone_all...) {
			display: none;
		}
	}
}

.header_outside {
	position: relative;
	height: 155px;
	background: linear-gradient(90deg, #01bf52 13.13%, #01acbd 90.45%);
	margin-top: 1rem !important;
	margin-bottom: 1.5rem !important;
	border-radius: 15px;
	padding: 0 !important;
	border: 2px solid transparent;

	@include media($phone_all...) {
		height: fit-content;
		margin-top: 0.5rem !important;
	}

	.header {
		height: 100%;
		width: 100%;
		display: flex;
		background-color: #121212;
		border-radius: 15px;

		@include media($phone_all...) {
			flex-direction: column;
		}

		.info_text {
			font-weight: 300;
			font-size: 10px;
			line-height: 14px;
			color: #8c8c8c;
			flex: 1;
			padding: 6px;
			margin-top: 0.5rem;
			margin-left: 1rem;
			display: flex;
			align-items: flex-start;

			svg {
				color: #8c8c8c;
				width: 12px;
				height: 12px;
				margin-right: 4px;
				margin-top: 2px;
			}
		}

		.socials {
			border-radius: 14px 0 0 14px;
			background-color: #000;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include media($phone_all...) {
				border-radius: 14px 14px 0 0;
				padding: 10px;
			}

			&>div {
				flex: 1;
			}

			.top {
				display: flex;
				justify-content: space-evenly;
				align-items: flex-end;

				@include media($phone_all...) {
					flex-wrap: wrap;
				}

				&>div {
					background: linear-gradient(270deg, #d900c0 12.82%, #016eda 75.21%);
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 46px;
					margin-top: 2.25rem;
					position: relative;

					.chooseRating {
						position: absolute;
						bottom: -24px;
						left: 0px;
						@include flexbox(row, flex-start, center);

						p {
							text-wrap: nowrap;
							margin: 0px;
							padding: 0px 4px;
							font-size: 0.4em;
							color: #fff;
						}

						.small_font {
							font-size: 14px;
						}
					}

					@include media($phone_all...) {
						margin: 5px;
						width: 40%;
					}

					&>svg {
						display: none;
					}

					&.disabled {
						background: linear-gradient(270deg, #540046 12.82%, #04284f 75.21%);

						&>svg {
							display: block;
							position: absolute;
						}

						button {
							background-color: #07111e;
							color: #595959;

							svg {
								color: #113d47;
							}
						}
					}

					button {
						background: #152d53;
						border-radius: 10px;
						width: calc(100% - 2px);
						height: 44px;

						svg {
							color: #53abc9;
						}

						&.overallButton {
							text-wrap: nowrap;
							line-height: 21px;
						}

						.ratingBox {
							@include flexbox(row, center, center);
							column-gap: 6px;
							box-sizing: border-box;
							margin: 0px;

							p {
								margin: 0px;
								padding: 0px 4px;
								font-size: 1em;
								color: #fff;
								font-weight: 600;
							}

							svg {
								width: 15px;
								height: 15px;
							}
						}
					}
				}
			}

			p {
				@extend .info_text;
			}
		}
	}
}

.projectinfo {
	margin-top: 2rem !important;
	float: left;
	display: block;
	width: 100%;

	h5 {
		font-weight: 700;
		margin-bottom: 1rem;
		font-size: 32px;
		line-height: 24px;
	}

	ul {
		margin: 0 0;
		padding: 0;
		list-style: none;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		li {
			margin-right: 10px;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 24px;
			padding: 4px 18px;
			border-radius: 20px;
			background: #00000080;
			margin-bottom: 10px;

			&:first-child {
				font-weight: 600;
				text-transform: uppercase;
				color: #000;

				@include media($phone_all...) {
					margin-right: 45%;
				}
			}
		}
	}

	.media {
		h3 {
			@extend .subtitle;
		}

		.images {
			display: flex;

			&>img {
				max-height: 130px;
				border: 1px solid #ffffff;
				border-radius: 11px;
				margin-right: 25px;
			}
		}
	}

	.documents {
		h3 {
			@extend .subtitle;
		}

		&>div {
			display: flex;
			justify-content: flex-start;

			a {
				font-weight: 300;
				font-size: 14px;
				line-height: 24px;
				color: #ffffff;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 10px;
				height: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
				padding: 12px 18px;

				svg {
					color: #08f996;
					margin-right: 8px;
					font-size: 20px;
				}
			}
		}
	}

	.show_about {
		width: 100%;
		margin-top: 2.02rem;
		text-align: center;
		display: flex;
		justify-content: center;
		position: relative;

		button {
			background: rgba(0, 0, 0, 0.5);
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 20px;
			padding: 4px 26px;
		}

		&.show_more::before {
			content: '';
			width: 100%;
			position: absolute;
			height: 5rem;
			bottom: 65px;
			background: linear-gradient(180deg, rgba(33, 32, 46, 0) 0, #121212 90%);
			z-index: 1;
		}
	}

	.about {
		display: block;
		color: #bcb2aa;
		line-height: 1.3rem !important;
		font-size: 0.9rem;
		margin: 0.5rem 0;

		&.showMore {
			max-height: 320px;
			overflow: hidden;
		}

		img {
			max-width: 100%;
		}

		h3 {
			@extend .subtitle;
		}

		p {
			display: block;
			color: #bcb2aa;
			line-height: 1.3rem;
			font-size: 0.9rem;
			margin: 0.5rem 0;
			letter-spacing: 0.1rem;
		}
	}
}

.badges_achievements {
	background: rgba(0, 0, 0, 0.35);
	border-radius: 20px;
	padding: 30px;
	margin-top: 2.5rem !important;

	@include media($phone_all...) {
		max-width: 100%;
		padding: 10px;
	}

	h3 {
		@extend .subtitle;

		&:first-child {
			margin-top: 5px;
		}
	}
}