@import 'Variable';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stakes_section {
	margin-bottom: 30px;

	> div {
		@include media($phone_all...) {
			width: 100%;
		}
	}

	.stats {
		background: #000;
		padding: 0.5rem 1.5rem;
		border-radius: 8px;
		border: 1px solid rgb(33,150,243);
		
		h1 {
			display: block;
			font-size: 1rem;
			width: 100%;
			color: rgb(33,150,243);
		}
	}

	.explanations {
		margin-top: 1rem;

		@include media($phone_all...) {
			
			padding: 0;
		}

		p {
			font-size: 16px;
			line-height: 1.1rem;
			margin-bottom: 1.3rem;
		}
	}

	h1 {
		display: block;
		font-size: 1rem;
		width: 100%;
	}

	.fan_staked {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1rem;

		.total_stakers,
		.total_staked,
		.your_stake {
			text-transform: uppercase;
			font-size: 1rem;
			font-weight: 600;
			margin: 0;
			padding: 1rem 0.5rem 0.5rem;
			border-radius: 8px;
			width: 50%;
			align-items: center;
			text-align: center;

			p {
				display: block;
				font-size: 2rem;
				font-weight: 600;
				margin: 0 0 0.4rem 0;

				span {
					font-size: inherit;
					font-weight: inherit;
				}
			}
		}

		.your_stake {
			background: #33405D;
		}

		img,
		svg {
			width: 2rem;
			margin-right: 0.5rem;
			color: $linkColorOther;
		}

		span {
			font-size: 12px;
			font-weight: 600;
			padding: 7px 0 0 5px;
		}
	}

	h4 {
		font-size: 24px;

		@include media($phone_all...) {
			max-width: 100%;
		}
	}

	table {
		width: 100%;
		font-size: 1.1rem;
		text-align: left;
		background: none;
		padding: 0.5rem;
		border-collapse: collapse;

		.center {
			text-align: center;
		}

		th {
			font-weight: normal;
			text-transform: uppercase;
			font-size: 0.8rem;
			padding: 0.5rem;
			border-bottom: none;

			@include media($phone_all...) {
				font-size: 14px;
				letter-spacing: normal;
				line-height: 18px;
			}
		}

		tr {
			&:hover {
				background-color: $bodyBg;
			}
		}

		td {
			@include media($phone_all...) {
				font-size: 12px;
				letter-spacing: normal;
				line-height: 18px;
			}

			a {
				color: $whiteColor;
				display: block;
				padding: 0.5rem;
			}

			button {
				&:disabled {
					color: #fffdfd78;
				}
			}

			&.status {
				span {
					color: #fff;
					text-transform: uppercase;
					padding: 0.15rem 0.75rem;
					font-size: 0.75rem;
					border-radius: 0.25rem;

					@include media($phone_all...) {
						font-size: 13px;
						letter-spacing: normal;
						padding: 2px 6px;
					}
				}

				.completed {
					background: #2dce89;
				}

				.processing {
					background: #cebe2d;
				}

				.reclaimed {
					background: #ce452d;
				}
			}
		}
	}
}

.message_box {
	display: flex;
	align-content: center;
	background: #1e1d47;
	border-radius: 8px;
	padding: 1rem;
	cursor: pointer;

	@include media($phone_all...) {
		width: 100%;
	}

	img {
		float: left;
		margin-left: 1rem;
		margin-right: 1em;

		@include media($phone_all...) {
			width: 70px;
			margin-left: 0.5rem;
			margin-right: 0.5em;
		}
	}

	h1 {
		font-size: 20px;
		line-height: 1.1rem;
		display: flex;
		align-items: center;
		font-weight: 600;

		@include media($phone_all...) {
			margin-top: 0;
			font-size: 16px;
		}
	}
}
