.badges {
	display: flex;
	margin-bottom: 20px;
	position: relative;

	.badge {
		display: flex;
		flex-direction: column;
		position: relative;
		margin: 0 4px;
		text-align: center;

		.mask {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
		}

		img {
			width: 88px;
			height: 95px;
		}

		p {
			margin-top: 1rem;
			color: #8c8c8c;
			font-size: 0.7rem;
			line-height: 1rem;
			font-weight: 300;
		}
	}

	button {
		font-size: 20px;
		height: 20px;
		background: transparent;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.prev {
			left: -20px;
			height: 100%;
			background: linear-gradient(90deg, #080808 53.9%, #ffffff00 104.95%);
			z-index: 10;
			border-radius: 0;
			width: 50px;
			box-shadow: 10px 0px 12px 3px #0a0a0a;
			background-color: #0a0a0a;
		}

		&.next {
			right: -20px;
			height: 100%;
			background: linear-gradient(
				90deg,
				#080808 53.9%,
				rgba(255, 255, 255, 0) 104.95%
			);
			z-index: 10;
			border-radius: 0;
			width: 50px;
			box-shadow: -9px 0px 12px 3px #0a0a0a;
			background-color: #0a0a0a;
		}
	}
}
