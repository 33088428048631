@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

#stake_dialogue {
	padding: 0.5rem 1.5rem 1.5rem;
	position: relative;
	background-color: #192531;
	border-radius: 8px;
	
	input {
		background-color: #193044 !important;
		border-color: #1D639B !important;
	}

	@include media($phone_all...) {
		padding: 1rem 1rem;
	}

	.headline {
		margin-bottom: 10px;
	}
	
	.fgrBreakdown {
		font-size: 15px;
	}

	.button_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;

		button {
			height: 50px;
			padding: 0.11rem 1.5rem !important;
			background-color: #fff;
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			border-radius: 8px;
			text-transform: uppercase;
			align-self: baseline;
			margin-left: 0.25rem;
		}
	}

	p {
		font-weight: 600;
		font-size: 14px;
		margin: 0;
	}

	.close {
		float: right;
		font-size: 1rem;
		color: #fff;
	}

	.info {
		display: flex;
		justify-content: space-between;

		small {
			margin: 0;
		}

		.a {
			flex: 1;
		}
	}

	.controls {
		text-align: left;
		min-width: 210px;
		flex: 1;

		a {
			font-size: 0.9rem;
			color: #fff;
		}
	}

	small {
		display: inline-block;
		font-size: 0.8rem;
		line-height: 1rem;
		margin: 1rem 0;

		@include media($phone_all...) {
			line-height: 1.1rem;
			margin: 0.75rem 0;
		}

		&.danger {
			color: #d32f2f;
		}
	}

	.token_input {
		margin: 0.5rem 0;
		float: left;
		width: 100%;
		position: relative;
		background: #2e2c6a;
		border-radius: 8px;
		padding: 4px 8px;
		border: 1px solid #5553da;

		img {
			width: 1.75rem;
			margin-top: 0.5rem;
			margin-left: 0.5rem;
			position: absolute;
		}

		input {
			display: inline-block;
			font-family: 'Titillium Web', sans-serif;
			font-weight: light;
			width: 100%;
			padding: 0.5rem;
			outline: 0;
			border: none;
			border-radius: 0.5rem;
			background: #2e2c6a;
			color: #ffffff;
			appearance: none;
			font-size: 1rem;
		}

		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}

	.disclaimer {
		float: left;
		margin-top: -2.5rem;
		line-height: 0.9rem;
		text-align: left;
		font-size: 0.7rem;
		width: 65%;
		display: initial;
	}
	
	.cancelButton {
		margin-left: 1rem;
		padding: 0 1.5rem;
		height: 38px;
		border-radius: 6px;
		border: 1px solid rgba(255, 255, 255, 0.5);
	}

	.green {
		color: #55f895;
		font-weight: 600;
	}

	.space {
		margin-right: 6px;
	}
}