.progress_container {
	position: relative;
	width: 100%;
	height: 15px;
	border-radius: 30px;
	display: inline-flex;
	transition: width 0.1s ease-in-out;
	animation: pullout 0.6s linear forwards;
	mask: linear-gradient(#fff0 0 0) padding-box, linear-gradient(#fff 0 0);
	mask-composite: exclude;
	.progress_bar1,
	.progress_bar2,
	.progress_bar3,
	.progress_bar4 {
		padding: 0;
		position: relative;
		height: 100%;
	}
	.progress_bar1 {
		background: #08f996;
		border-radius: 30px 0px 0px 30px;
	}
	.progress_bar2 {
		background: #dfd600;
	}
	.progress_bar3 {
		background: #5bcdec;
	}
	.progress_bar4 {
		background: #ec865b;
		border-radius: 0px 30px 30px 0px;
	}
}
@keyframes pullout {
	0% {
		width: 0%;
	}
	50% {
		width: 50%;
	}
	100% {
		width: 100%;
	}
}
