@import 'ThemeScss';

.select_box_menu_item {
	color: $whiteColor !important;

	&:hover,
	&[aria-selected='true'] {
		background-color: rgb(34, 34, 34) !important;
	}

	.image_variant {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		img {
			height: 54px;
			border-radius: 6px;
			margin-left: 6px;
		}
	}
}

.select_box {
	width: 100%;
	margin-bottom: 15px !important;

	h2 {
		margin-bottom: 10px;
	}

	.label {
		color: #ffffff;
		margin-bottom: 10px;
	}

	> div {
		height: 45px;
		width: 100%;
		border-radius: 0.5rem;
	}

	svg {
		font-size: 2rem;
	}

	h2 {
		margin-bottom: 0.5rem;
		color: #fff;
	}

	&.error {
		& > div {
			border: 1px solid #d32f2f;
		}

		p,
		h2,
		.label {
			color: #d32f2f !important;
		}
	}

	.black {
		border: 1px solid #0c0b2f;

		&:focus,
		&:hover {
			border: 1px solid #2e2bba !important;
		}

		> div {
			background-color: #0c0b2f;
		}

		fieldset {
			border: none;

			&:focus,
			&:hover {
				border: 1px solid #746064 !important;
			}
		}

		svg {
			color: #ffffff;
		}
	}

	.grey {
		> div {
			background-color: #262626;
		}

		fieldset {
			border: none;
		}

		svg {
			color: #4a4a4a;
		}
	}

	.blue {
		> div {
			background-color: #2e2c6a;
			border-radius: 8px;
			border: 1px solid #5553da;
		}
	}

	.blue_sidebar {
		> div {
			background-color: #2e2c6a;
			border-radius: 8px;
			border: 1px solid #5553da;
		}
	}
}
