@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.flexBox {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dialog {
	z-index: 997 !important;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	.dialog_paper {
		width: 384px;
		max-height: 845px;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		background: linear-gradient(180deg,
				rgba(45, 42, 180, 0.5) 0%,
				rgba(20, 18, 78, 0.5) 100%);
		backdrop-filter: blur(3px);

		@include media($phone_all...) {
			margin-top: 0;
			max-height: 550px;
		}

		@include media($desktop_all...) {}

		.dialog_content {
			padding: 24px;

			.score_detail {
				color: #fff;

				.title {
					h1 {
						color: #fff;
						line-height: 1.9rem;
						display: block;
						font-family: Titillium Web;
						font-size: 1.5rem;
						font-weight: 700;

						@include media($phone_all...) {
							font-size: 1.5rem;
						}
					}

					span {
						text-transform: uppercase;
						font-size: 1rem;
						font-weight: 600;
						display: block;
					}
				}

				.legend {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					// border-right: 0.05rem solid #596f93;
					padding-left: 2px;

					.legend_item {
						display: flex;
						align-items: center;

						p {
							font-size: 1.1rem;
							display: inline-block;
							min-width: 55px;
							font-family: 'Titillium Web';
							font-size: 14px;
							font-weight: 300 !important;
							line-height: 24px;
						}

						.bg_blue,
						.bg_green,
						.bg_orange,
						.bg_yellow {
							width: 2.75rem;
							height: 0.5rem;
							border-radius: 0.5rem;
							margin-left: 1rem;
						}
					}
				}

				.goScoreModal {
					margin-top: -15px;
				}

				.explainerHeader {
					font-size: 0.88rem;
					margin-top: 1rem;
					margin-left: 0px;
					font-family: Titillium Web;
					font-weight: 700;
					line-height: 1.5rem;

					@include media($phone_all...) {
						margin-top: 0.5rem;
					}
				}

				.explainer {
					font-size: 0.88rem;
					line-height: 0.9rem;
					margin-left: 1px;
					margin-bottom: 16px;
					font-family: 'Titillium Web';
					font-weight: 300;
					line-height: 1.5rem;
				}

				.info_container {
					background: #2f2cbb80;
					width: 100%;
					margin-top: 24px;
					border-radius: 20px;
					padding: 5px 0;

					@include media($phone_all...) {
						margin-top: 15px;
					}

					.info_item {
						display: flex;
						justify-content: space-between;
						margin: 10px 20px;

						@include media($phone_all...) {
							margin: 7px 20px;
						}

						&.active {
							p {
								text-shadow: 0px 0px 10px #ffffff;
							}
						}

						.info_title {
							display: flex;
							align-items: center;

							img,
							svg {
								width: 20px !important;
								margin-right: 10px;
							}
						}

						p {
							font-family: 'Titillium Web';
							font-size: 0.88rem;
							font-weight: 300;
							line-height: 24px;
							color: #fff;
						}
					}
				}

				.ranking {
					margin: 15px auto;

					p {
						text-align: center;
						font-size: 0.88rem;
						font-weight: 300;
						font-family: 'Titillium Web';
						line-height: 24px;
					}

					.withFire {
						@extend .flexBox;
						gap: 4px;
					}

					.fire_container {
						width: 20px;
						@extend .flexBox;
					}
				}

				.ranking_footer {
					margin-top: 20px;

					p {
						font-size: 0.8rem;
						font-weight: 700;
						line-height: 1rem;
						width: 60%;
						margin: auto;

						span {
							color: #aaa;
							font-weight: 100;
						}
					}
				}

				.bg_green {
					background-color: #08f996;
				}

				.green {
					color: #08f996;
				}

				.bg_yellow {
					background-color: #dfd600;
				}

				.yellow {
					color: #dfd600;
				}

				.bg_blue {
					background-color: #5bcdec;
				}

				.blue {
					color: #5bcdec;
				}

				.bg_orange {
					background-color: #ec865b;
				}

				.orange {
					color: #ec865b;
				}
			}
		}
	}
}