.profile_link {
	display: flex;
	flex-direction: row;

	.info_container {
		margin-left: 0px;
		display: flex;
		flex-direction: column;
		line-height: 1rem;
		letter-spacing: initial;
		justify-content: center;
		color: #fff;

		.display_name {
			display: flex;
			font-style: normal;
			letter-spacing: 0.8rem;

			p {
				letter-spacing: 0;
				font-weight: bold;
				font-size: 18px;
			}

			svg {
				color: #1aeff3;
				font-size: 13px;
				margin-left: 4px;
				margin-top: 0px;
			}
		}

		.profession {
			font-style: normal;
			letter-spacing: initial;
			font-weight: 400;
			font-size: 0.8rem;
			margin-top: -0.2rem;

			a {
				font-size: 0.8rem;
			}
		}

		.membership {
			font-weight: 600;
			font-size: 12px;
			line-height: 24px;
		}
	}

	.author {
		display: flex;
		flex-direction: column;
		font-size: 0.9rem;
		font-weight: normal;
		font-family: 'Titillium Web', sans-serif;
		line-height: 1rem;
		color: #fff;

		.meta {
			font-size: 0.8rem;

			a {
				color: #59b7d8;
				text-decoration: underline;
				margin-right: 0.25rem;
			}
		}
	}
}
