.search_input {
	svg {
		left: 30px;
		position: relative;
		font-size: 14px;
		color: #4a4a4a;
	}

	.close {
		svg {
			color: #3e6ab1;
		}
	}
}
