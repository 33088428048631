@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

@mixin flex-box($flex-direction: row,
	$justify-content: flex-start,
	$align-items: flex-start) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

.dialog {
	z-index: 1350 !important;

	.dialog_paper {
		width: 456px;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);

		.dialog_content {
			padding: 30px;

			h2 {
				width: 100%;
				color: #fff;
				text-align: center;
				font-size: 30px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px;
				letter-spacing: normal;
				margin-bottom: 15px;
			}

			p {
				letter-spacing: normal;
				color: #fff;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 15px;
				hyphens: none;
			}

			.actions {
				display: flex;
				justify-content: center;

				button {
					border-radius: 25px;
					background: #4e4bde;
					color: #fff;
					height: 35px;
					padding: 0 32px;
				}

			}
		}
	}

	&.large {
		.dialog_paper {
			width: auto;
		}

		.dialog_content {
			.actions {
				button {
					padding: 26px 32px;
				}
			}
		}
	}

	&.warning {
		.dialog_paper {
			width: auto;
			overflow: visible;
		}

		img {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -90px;
			z-index: 10;
			width: 340px;
			height: 155px;
		}

		.dialog_content {
			overflow: auto;

			h2 {
				margin-top: 60px;
			}

			p {
				text-align: left;
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 30px;
			}

			.actions {
				.approve_btn {
					background: #11113a;
				}
			}
		}
	}

	&.unfollow_warning {
		.dialog_content {
			.actions {
				display: flex;
				align-items: center;
				flex-direction: row-reverse;
				gap: 10px;

				.approve_btn {
					background: transparent;
					color: #d2c8c8;
					font-weight: 600;

					&:hover {
						text-decoration: underline;
						background-color: #ffffff0a;
					}
				}
			}
		}
	}

	&.unfollow_warning_new {
		.dialog_paper {
			position: relative;
			border-radius: 15px;
			border: 1px solid rgba(33, 150, 243, 0.5);
			background: #0A1C2A;
			color: #ffffff;
			margin-top: 10%;
			padding: 12px;

			@include media($phone_all...) {
				min-width: 335px;
				padding: 10px 5px 5px 5px;
				margin-top: 28%;
			}

			.close_icon {
				position: absolute;
				right: 21px;
				top: 21px;
				cursor: pointer;

				@include media($phone_all...) {
					right: 5px;
					top: 5px;
				}
			}

			.closeMobile {
				display: none;

				@include media($phone_all...) {
					display: block;
					width: 303px !important;
					margin-left: -17px;
					margin-top: 16px;

					button {
						width: 100%;
						background: #152d53;
						border-radius: 10px;
						height: 46px;

						&:before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							border-radius: 10px;
							border: 2px solid transparent;
							border-left-color: #016eda;
							border-right-color: #d900c0;
							background: linear-gradient(270deg, #d900c0 12.82%, #016eda 75.21%);
							-webkit-mask: linear-gradient(#fff 0 0) padding-box,
								linear-gradient(#fff 0 0);
							-webkit-mask-composite: destination-out;
							mask-composite: exclude;
						}
					}
				}
			}

			.dialog_content {

				@include media($phone_all...) {
					padding: 30px 10px;
				}

				p {
					@include media($phone_all...) {
						font-size: 19px !important;
					}
				}

				// overflow: hidden;
				&::-webkit-scrollbar {
					display: none;
				}

				&::-webkit-scrollbar-thumb {
					display: none;
				}

				&::-webkit-scrollbar-track {
					display: none;
				}

				.actions {
					display: flex;
					align-items: center;
					flex-direction: row-reverse;
					margin-top: 30px;

					button {
						opacity: 0.85;
					}

					button:nth-child(1) {
						background: none;
					}

					button:hover {
						opacity: 1;
					}
				}

				.container {
					@include flex-box(column, center, center);
					row-gap: 24px;

					@include media($phone_all...) {
						row-gap: 8px;
						@include flex-box(column, center, flex-start);
						width: 303px;
					}

					.title {
						padding: 0px;
						margin: 0px;

						h3 {
							font-family: Titillium Web;
							font-size: 20px;
							font-weight: 700;
							line-height: 30.42px;
							text-align: left;
							color: #ffffff;

							@include media($phone_all...) {
								font-size: 16px;
								line-height: 24.34px;
								width: 100%;
							}
						}
					}

					.content {
						background: #000000bf;
						padding: 24px;
						color: #ffffff;
						@include flex-box(column, center, center);
						row-gap: 24px;
						width: 573px;
						border-radius: 16px;

						@include media($phone_all...) {
							min-width: 100%;
							width: 100%;
							padding: 0px;
							background: transparent;
							row-gap: 16px;
						}

						.text {
							font-family: Titillium Web;
							font-size: 16px;
							font-weight: 400;
							line-height: 24.34px;
							color: #ffffff;

							.boldBox {
								font-size: 16px;
								font-weight: 600;
								line-height: 24.34px;

								@include media($phone_all...) {
									font-size: 14px;
									line-height: 21.29px;
								}
							}

							@include media($phone_all...) {
								font-size: 14px;
								line-height: 21.29px;
							}
						}

						h3 {
							font-family: Titillium Web;
							font-size: 20px;
							font-weight: 700;
							line-height: 30.42px;

							@include media($phone_all...) {
								font-size: 16px;
								line-height: 24.34px;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}