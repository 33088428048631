.medias {
	display: flex;
	margin-bottom: 20px;
	position: relative;

	.snap_item {
		display: flex;

		& > img {
			max-height: 130px;
			border: 1px solid #ffffff;
			border-radius: 11px;
			margin-right: 25px;
		}
	}

	button {
		font-size: 20px;
		height: 20px;
		background: transparent;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.prev {
			left: 0;
			height: 101%;
			// background: linear-gradient(90.09deg, rgba(18, 18, 18, 0) 22.45%, #121212 43.11%);
			background-image: linear-gradient(
				to right,
				#121212 60.11%,
				rgba(255, 0, 0, 0)
			);
			z-index: 10;
			border-radius: 0;
			width: 50px;
			padding-right: 3rem;
		}

		&.next {
			right: 0;
			height: 101%;
			background-image: linear-gradient(
				to left,
				#121212 60.11%,
				rgba(255, 0, 0, 0)
			);
			z-index: 10;
			border-radius: 0;
			width: 50px;
			padding-left: 3rem;
		}
	}
}
