@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

@mixin flex-box(
	$flex-direction: row,
	$justify-content: flex-start,
	$align-items: flex-start
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

.super_fun {
	z-index: 997 !important;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
	}

	&::-webkit-scrollbar-track {
		display: none;
	}

	.dialog_paper {
		position: relative;
		min-width: 637px;
		min-height: 414px;
		border-radius: 30px;
		border: 1px solid #2e2bba;
		color: #ffffff;
		margin-top: 10%;
		background: linear-gradient(
			180deg,
			rgba(46, 43, 186, 0.5) 0%,
			rgba(30, 29, 71, 0.5) 100%
		);
		backdrop-filter: blur(3px);
		padding: 22px;

		@include media($phone_all...) {
			min-width: 335px;
			background: #060607;
			padding: 40px 10px 10px 10px;
			margin-top: 28%;
			border-radius: 8px;
			border: none;
		}

		.close_icon {
			position: absolute;
			right: 21px;
			top: 21px;
			cursor: pointer;

			@include media($phone_all...) {
				right: 5px;
				top: 5px;
			}
		}

		.closeMobile {
			display: none;

			@include media($phone_all...) {
				display: block;
				width: 303px !important;
				margin-left: -17px;
				margin-top: 16px;

				button {
					width: 100%;
					background: #152d53;
					border-radius: 10px;
					height: 46px;

					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						border-radius: 10px;
						border: 2px solid transparent;
						border-left-color: #016eda;
						border-right-color: #d900c0;
						background: linear-gradient(270deg, #d900c0 12.82%, #016eda 75.21%);
						-webkit-mask: linear-gradient(#fff 0 0) padding-box,
							linear-gradient(#fff 0 0);
						-webkit-mask-composite: destination-out;
						mask-composite: exclude;
					}
				}
			}
		}

		.dialog_content {
			// overflow: hidden;
			&::-webkit-scrollbar {
				display: none;
			}

			&::-webkit-scrollbar-thumb {
				display: none;
			}

			&::-webkit-scrollbar-track {
				display: none;
			}

			.container {
				@include flex-box(column, center, center);
				row-gap: 24px;

				@include media($phone_all...) {
					row-gap: 8px;
					@include flex-box(column, center, flex-start);
					width: 303px;
				}

				.title {
					padding: 0px;
					margin: 0px;

					h3 {
						font-family: Titillium Web;
						font-size: 20px;
						font-weight: 700;
						line-height: 30.42px;
						text-align: left;
						color: #ffffff;

						@include media($phone_all...) {
							font-size: 16px;
							line-height: 24.34px;
							width: 100%;
						}
					}
				}

				.content {
					background: #000000bf;
					padding: 24px;
					color: #ffffff;
					@include flex-box(column, center, center);
					row-gap: 24px;
					width: 573px;
					border-radius: 16px;

					@include media($phone_all...) {
						min-width: 100%;
						width: 100%;
						padding: 0px;
						background: transparent;
						row-gap: 16px;
					}

					.text {
						font-family: Titillium Web;
						font-size: 16px;
						font-weight: 400;
						line-height: 24.34px;
						color: #ffffff;

						.boldBox {
							font-size: 16px;
							font-weight: 600;
							line-height: 24.34px;
							@include media($phone_all...) {
								font-size: 14px;
								line-height: 21.29px;
							}
						}

						@include media($phone_all...) {
							font-size: 14px;
							line-height: 21.29px;
						}
					}

					h3 {
						font-family: Titillium Web;
						font-size: 20px;
						font-weight: 700;
						line-height: 30.42px;

						@include media($phone_all...) {
							font-size: 16px;
							line-height: 24.34px;
							width: 100%;
						}
					}

					.superfun_imgs {
						@include flex-box(row, space-between, center);
						row-gap: 24px;

						.img_box {
							@include media($phone_all...) {
								@include flex-box(row, space-between, center);
								flex: 1 1 30%;
								max-width: 30%;
							}

							img {
								width: 151px;
								height: 219px;
								object-fit: contain;
								@include media($phone_all...) {
									width: 100%;
									border-radius: 5px;
								}
							}
						}
					}

					.superfun_recog {
						@include flex-box(row, space-between, center);
						row-gap: 24px;

						@include media($phone_all...) {
							width: 100%;
							@include flex-box(column, space-between, center);
							row-gap: 8px;
						}
					}

					.colored_box {
						background: linear-gradient(
							237.28deg,
							#15023e 1.38%,
							#23093a 52.17%,
							#250a3a 100%
						);
						min-width: 160px;
						padding: 16px 10px;
						border-radius: 4px;
						width: 160px;
						@include flex-box(column, space-between, flex-start);
						row-gap: 4px;

						@include media($phone_all...) {
							width: 100%;
							background: linear-gradient(
								90deg,
								#15023e 0%,
								#23093a 49.5%,
								#250a3a 100%
							);
						}

						h4 {
							font-family: Titillium Web;
							font-size: 13px;
							font-weight: 600;
							line-height: 19.77px;

							@include media($phone_all...) {
							}
						}

						.text {
							font-family: Titillium Web;
							font-size: 12px;
							font-weight: 400;
							line-height: 15px;

							@include media($phone_all...) {
							}
						}
					}
				}
			}
		}
	}
}
