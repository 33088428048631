@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.profile_link {
	display: flex;
	flex-direction: row;

	.info_container {
		margin-left: 0px;
		display: flex;
		flex-direction: column;
		line-height: 1rem;
		letter-spacing: initial;
		color: #fff;

		.display_name {
			display: flex;
			font-family: 'Titillium Web';
			font-style: normal;
			letter-spacing: 0.8rem;
			font-weight: 700;
			font-size: 1rem;

			p {
				letter-spacing: 0;
			}

			svg {
				color: #1aeff3;
				font-size: 13px;
				margin-left: 4px;
				margin-top: 1px;
			}
		}

		.profession {
			font-style: normal;
			letter-spacing: initial;
			font-weight: 400;
			font-size: 0.8rem;
			margin-top: -0.2rem;
			max-width: 100%;

			@include media($phone_all...) {
				margin-top: 0;
				max-width: 230px;
				line-height: 15px;
			}
		}

		.membership {
			font-weight: 600;
			font-size: 12px;
			line-height: 24px;
		}
	}

	.author {
		display: flex;
		flex-direction: column;
		font-size: 0.9rem;
		font-weight: normal;
		font-family: 'Titillium Web', sans-serif;
		line-height: 1rem;
		color: #fff;

		em {
			font-style: normal;
			display: block;
			color: #fff;
			font-size: 0.8rem;
			line-height: 1rem;
		}
	}
}
