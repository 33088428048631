@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.edit_tweet {
	display: flex;
	flex-direction: column;
	width: 100%;

	h2 {
		margin-bottom: 10px !important;
	}

	.header {
		border-radius: 10px;
		margin-bottom: 15px;
	}

	textarea {
		@include media($phone_all...) {
			margin-bottom: 0;
		}
	}

	.link {
		display: flex;
		align-items: flex-end;

		button {
			margin-left: 10px;
			margin-bottom: 6px;
		}

		.link_copied {
			font-size: 0.875;
			color: #fff;

			svg {
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}

	.copy_link {
		display: none;
		font-size: 12px;
		margin-bottom: -10px;
		text-align: right;
		color: #fff;

		svg {
			color: #fff;
			font-size: 12px;
		}

		@include media($phone_all...) {
			display: block;
		}
	}

	.footer {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-top: 20px;

		button {
			padding: 4px 24px;
		}

		.share_btn {
			background-color: #4e4bde;
			border-radius: 25px;
			margin-left: 10px;

			&:hover {
				background-color: #4e4bdea8;
			}

			@include media($phone_all...) {
				display: none;
			}
		}

		.share_link {
			background-color: #4e4bde;
			border-radius: 25px;
			margin-left: 10px;
			padding: 4px 24px;
			font-size: 14px;
			color: #fff;
			display: none;

			&:hover {
				background-color: #4e4bdea8;
			}

			@include media($phone_all...) {
				display: block;
			}
		}
	}

	.note {
		font-size: 12px;
		margin-top: 15px;
		line-height: 15px;

		@include media($phone_all...) {
			display: none;
		}
	}
}

.backDrop {
	position: relative;
	border: 2px solid #fff;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	background: linear-gradient(
		137.48deg,
		rgba(255, 83, 188, 0.5) 15.13%,
		rgba(10, 252, 212, 0.5) 87.01%
	);
	backdrop-filter: blur(20.9709px);
	vertical-align: middle;
	margin-right: 15px;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 15px !important;
		height: 15px !important;
	}
}
