.container {
	margin-top: 20px;

	.creator_container {
		display: flex;
		gap: 16px;
		justify-content: center;
		align-items: center;
		margin-left: 24px;
		padding: 16px 24px;
		border-radius: 16px;
		.fireIcon {
			margin-left: 6px;
		}
		.iconType {
			width: 48px;
			height: 48px;

			.icon img {
				width: 100%;
				height: 100%;
			}
		}

		.creatorType {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.creatorTop {
				font-family: Titillium Web;
				font-size: 16px;
				font-weight: 400;
				line-height: 20px;
				text-align: left;
				color: rgba(255, 255, 255, 1);
			}

			.creatorText {
				font-family: Titillium Web;
				font-size: 16px;
				font-weight: 700;
				line-height: 20px;
				color: rgba(255, 154, 98, 1);
			}
		}
	}
	.option1,
	.option4 {
		position: relative;
		.creatorTop {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 4px;
		}
		::before {
			content: '';
			position: absolute;
			inset: 0;
			z-index: -1;
			border-radius: 16px;
			border: 3px solid transparent;
			background: linear-gradient(
				101.21deg,
				#ff9a62 -2.79%,
				#ff5b00 135.06%
			) !important;
			mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
			mask-composite: exclude;
		}
		.creatorText {
			color: rgba(255, 154, 98, 1);
		}
	}
	.option2 {
		position: relative;
		border: 3px solid rgba(71, 71, 71, 1);
		.creatorText {
			color: rgba(71, 71, 71, 1) !important;
		}
	}
	.option3 {
		position: relative;
		border: 3px solid rgba(91, 205, 236, 1);
		.creatorText {
			color: rgba(91, 205, 236, 1) !important;
		}
	}

	.score_container {
		display: flex;
		align-items: center;
		position: relative;

		svg {
			font-size: 14px;
		}

		.score {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			background-color: transparent;
			height: 104px;
			width: 104px;
			border-radius: 50%;

			&:hover {
				cursor: pointer;
			}

			.ring_image {
				position: absolute;
				width: 100%;
				border-radius: 50%;
			}

			.center_image {
				position: absolute;
				width: 45%;
				z-index: 2;
			}

			.center_text {
				z-index: 2;
				font-size: 40px;
				font-weight: 600;
				// background: -webkit-linear-gradient(#08add9, #6b3f97);
				background: #fafffd;
				box-shadow: none;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.text {
			font-size: 0.9rem;
			line-height: 1.15rem;
			width: 11.5rem;
			text-align: left;
			margin-left: 20px;
			color: #8c8c8c;

			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}

		.bar {
			position: absolute;
			z-index: 1;
		}
	}

	.bottom_text {
		margin: 10px 12px 24px;
		font-size: 1rem;

		sup {
			font-size: 0.6rem;
		}
	}
}
.lockedBar {
	box-shadow: none !important;
	&::after {
		box-shadow: none !important;
	}
}
