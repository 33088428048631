.stage_dialog {
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		width: 80px;
		height: 85px;
	}

	h1 {
		font-size: 32px;
		line-height: 36px;
		font-weight: 700;
		margin-top: 1rem;
	}

	p {
		margin-top: 12px;
		text-align: left;
		font-size: 0.9rem;
		padding: 0 0.5rem;

		span {
			color: #00bde8;
			cursor: pointer;
		}
	}

	.sub_text {
		width: 100%;
		border: 0.1rem double transparent;
		background-image: linear-gradient(#000, #000),
			radial-gradient(circle at left top, #016eda, #d900c0);
		background-origin: border-box;
		background-clip: padding-box, border-box;
		border-radius: 0.5rem;
		padding: 15px;
		margin-top: 12px;

		p {
			text-align: start;
			margin: 0;
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		width: 100%;

		button {
			color: #00bde8;
			font-size: 16px;
			padding: 0;

			svg {
				color: #00bde8;
			}

			&:hover {
				background: none;
				color: #fff;
			}
		}

		.btn_right {
			margin-left: auto;
		}
	}

	.vaultlock {
		display: flex;

		img {
			margin-right: 12px;
		}

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;

			p {
				font-size: 12px;

				&.vaultlock_date {
					color: #00bde8;
					margin-top: 10px;
				}
			}
		}
	}
}
