.password_rules {
	margin: 0;
	text-align: left;
	margin-bottom: 1rem;
	margin-top: 6px;
	font-size: 0.7rem;
	font-style: normal;
	letter-spacing: normal;

	.results {
		margin-top: 10px;
	}

	a {
		color: #fff;
	}

	p {
		margin: 0;
	}

	span {
		color: #ffffff33;
		display: block;
		line-height: 1rem;

		&.show_rule {
			color: #fff;
		}
	}

	p {
		margin: 0 0 5px 0;

		&.show_rule {
			color: #fff;
		}
	}

	.bar_100 {
		background-color: #0afd58;
	}

	.bar_66 {
		background-color: #fea903;
	}

	.bar_33 {
		background-color: #ff633d;
	}
}
