.progress_container {
	position: relative;
	background: none;
	width: 100%;
	height: 15px;
	padding: 2px;
	border-radius: 30px;
	border: 1px;
	border: 1px solid #ff7021;

	.progress_bar {
		padding: 0;
		position: relative;
		height: 100%;
		gap: 0px;
		border-radius: 10px 10px;
		background: linear-gradient(89.97deg, #ff5b00 0.02%, #ff9a62 108.92%);
		box-shadow: 0px 4px 4px 0px #ffffff40 inset;
		transition: width 0.1s ease-in-out;
		&:not(.no_fire)::after {
			content: url('../../../../assets/images/creatorType/fire.svg');
			position: absolute;
			z-index: 99999;
			right: -9px;
			top: -6px;
		}
	}
}
