.badge {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 4px;
	text-align: center;

	.mask {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: transparent;
	}

	img {
		width: 88px;
		height: 95px;
	}

	p {
		margin-top: -0.5rem;
		color: #8c8c8c;
		font-size: 0.7rem;
		line-height: 1rem;
		font-weight: 300;
		max-width: 90px;
	}
}
