@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stage_container {
	display: flex;
	align-items: center;
	position: relative;
	flex-direction: column;
	width: 112px;
	height: 100%;

	&:hover {
		cursor: pointer;
	}

	svg {
		position: absolute;
		font-size: 1rem;
		margin-top: -1.5rem;
	}

	.stage {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: transparent;
		height: 112px;
		width: 112px;
		border-radius: 50%;

		@include media($phone_all...) {
			height: 80px;
			width: 80px;
		}

		.ring_image {
			position: absolute;
			width: 100%;
			border-radius: 50%;
		}

		.center_image {
			position: absolute;
			width: 45%;
		}
	}

	.text {
		margin-top: 5px;
		width: 100%;
		display: block;
		color: #8c8c8c;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 300;
		text-align: center;

		@include media($phone_all...) {
			font-size: 12px;
		}
	}
}
